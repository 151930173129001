import {html, LitElement} from "lit";
import {customElement, query, queryAll, state} from "lit/decorators.js";
import {
    type BoardConfig,
    type ILiveBoard,
    type SectionConfig,
    type WidgetConfig,
    type RibbonConfig,
    type LiveWidget,
    isObjsEqual,
    hashObj,
    type IItemViewer,
    componentEmit,
    type PageConfig,
    type FloatingWidgetConfig,
    widgetEmit,
    type RibbonElement,
    type LiveConfig,
    type LoadableConfig,
    type FloatWidgetElement,
    type GridConfig,
    type LiveElement,
    type LoadableElement,
    type GridElement,
    getFloatingWidgetPosition,
    waitForFollozeScriptsToLoad,
    positionToGridArea, FlzEvent, emit, isInDesigner, getWidgetStyleByPosition,
} from "client-sdk";
import {unsafeHTML} from "lit/directives/unsafe-html.js";
import styles from "../styles/css/shadow/liveBoard.shadow.scss";
import stringify from "fast-json-stable-stringify";
import Throttle from 'lodash-decorators/throttle';
import {LiveBoardController} from './../controllers/LiveBoardController';
import sortBy from "lodash/sortBy";
import {runDisjointedEmbeddedLogic} from "../helpers/DisjointedEmbeddedBoardHelper";

@customElement("live-board")
export class LiveBoard extends LitElement implements ILiveBoard {
    static styles = styles;
    public boardId: number;

    @state()
    protected _config: BoardConfig;

    @query(".live-board")
    private gridEl: HTMLElement;

    @queryAll(".widget")
    public widgetsEl: LiveWidget[];

    private intersectionObserverElementsList: HTMLElement[] = [];
    private intersectionObserver: IntersectionObserver;

    private loadedScripts = new Map<string, Promise<void>>();
    public widgetScriptsLoadMap = new Map<string, string>();
    public configHash: string = "";
    private refreshTimeout: NodeJS.Timeout;
    public itemViewer: IItemViewer;
    private readonly isAutoUpgradeWidgets: boolean;
    private previousHeight: number;
    private previousWidth: number;
    private readonly landingPage: string;
    private idleTimer: NodeJS.Timeout;
    private idleComplete: boolean = false;

    // keeps the ids of widgets you only want to load once.
    private widgetsToLoadOnce: string[] = [];

    @state()
    private _currentPage: PageConfig;

    @state()
    private selectedPageName: string;
    private forceUpdate: boolean = false;
    private isPreRenderingFlag: boolean;
    private readonly isHostedInDesigner: boolean = false;

    private _isRenderPersonalization: boolean = true;
    private _isWidgetsLoaded: boolean = false;

    @state()
    private _personalizationResolved: {[key: string]: boolean} | undefined;

    public controller: LiveBoardController;

    @state()
    private liveBoardLoadedPercent = 0; // the first value to simulate loading to
    private isDisjointedEmbeddedBoard: boolean = false;

    constructor() {
        super();

        this.isAutoUpgradeWidgets = window["FollozeState"].initialState.board.auto_upgrade_widgets || true;
        this.landingPage = window["FollozeState"].initialState.board.landing_page;
        this.boardId = window["FollozeState"].initialState.board.id;

        this.selectedPageName = this.landingPage;
        if (!window["designer"]) {
            this.config = window["FollozeState"].initialState.board.config;
        } else {
            this.isHostedInDesigner = true;
            this._isRenderPersonalization = false;
        }
    }


    async connectedCallback() {
        // for dev purposes
        // @ts-ignore
        window.board = this;
        super.connectedCallback();

        // search for the widgets tmp elements and replace with real widgets versions
        if (document.querySelectorAll("flz-section-template").length > 0) {
            this.isDisjointedEmbeddedBoard = true;
            runDisjointedEmbeddedLogic(this);
        }

        if (this.isHostedInDesigner) {
            this.classList.add("designer");
            this.personalizationResolved = {};
        } else {
            // @ts-ignore todo: add this into initialState type
            this.personalizationResolved = window["FollozeState"].initialState.personalization_rules_results;

            // this is to prevent future users from using the window.board for their purposes
            // todo: remove all uses of window.board or window["board"] from the code.
            Object.defineProperty(window, 'board', {
                value: this,
                writable: false,
                configurable: true,
            });
        }

        emit(this, "board-element-connected");
    }

    firstUpdated() {
        this.intersectionObserver = new IntersectionObserver(entries => {
            // please make sure you only observe widgets
            entries.forEach(entry => {
                const widget = entry.target as LiveWidget;
                if (entry.isIntersecting) {
                    widget.onEnterViewport && widget.onEnterViewport(entry);
                } else {
                    widget.onLeaveViewport && widget.onLeaveViewport(entry);
                }
            });
        });

        new ResizeObserver(() => {
            this.onResize();
        }).observe(this);

        window.addEventListener("scroll", this.onScroll.bind(this));
    }

    themeOverrideReload() {
        let styleEl = document.head.querySelector("#flz-style-override");
        if (!styleEl) {
            styleEl = document.createElement("style");
            styleEl.id = "flz-style-override";
            document.head.appendChild(styleEl);
        }

        // @ts-ignore
        if (!this.config.theme.overrideRules?.activated) {
            styleEl.remove();
            return;
        }

        // @ts-ignore
        if (this.config.theme?.override) {
            styleEl.innerHTML = `:root {${
                // @ts-ignore
                Object.entries(this.config.theme.override)
                    .map(x => `${x[0]}: ${x[1]};`)
                    .join("\n")
            }}`;
        }
    }

    // @ts-ignore - todo: add this into ILiveBoard type in client-sdk
    set personalizationResolved(result: {[key: string]: boolean} | undefined) {
        console.timeEnd("enrichment started");
        console.time("enrichment resolved");
        this._personalizationResolved = result;
        if (result) {
            this.classList.remove("personalization-pending");
            this.classList.add("personalization-resolved");
            this.liveBoardLoadedPercent = 100;
            console.timeEnd("enrichment resolved");
        } else {
            this.classList.remove("personalization-resolved");
            this.classList.add("personalization-pending");
            this.liveBoardLoadedPercent = 88;
            this.controller.getEnrichment()
                .then(res => {
                    console.timeEnd("enrichment resolved");
                    setTimeout(() => this.liveBoardLoadedPercent = 100);
                    setTimeout(() => {
                        this.personalizationResolved = res.personalization_rules_results;
                        this.isPreRenderingFlag = false;
                        this.config = res.board_configuration;
                        this.triggerBoardReadyEvent();
                    });
                })
                .catch(e => {
                    console.error(e);
                    this.personalizationResolved = {};
                    this.isPreRenderingFlag = false;
                    this.refresh();
                    this.triggerBoardReadyEvent();
                    console.timeEnd("enrichment resolved");
                });
        }
    }

    // @ts-ignore - todo: add this into ILiveBoard type in client-sdk
    get personalizationResolved(): {[key: string]: boolean} | undefined {
        return this._personalizationResolved;
    }

    set isWidgetsLoaded(value: boolean) {
        this._isWidgetsLoaded = value;
        this.triggerBoardReadyEvent();
    }

    get isWidgetsLoaded(): boolean {
        return this._isWidgetsLoaded;
    }

    private triggerBoardReadyEvent() {
        if (this.isBoardReady()) {
            widgetEmit(this, "board-ready");
            console.debug("board is ready!");
            console.timeEnd("board is ready!");
        }
    }

    public isBoardReady() {
        return !!(this._isWidgetsLoaded && this._personalizationResolved);
    }

    public turnOffPersonalization() {
        this._isRenderPersonalization = false;
    }

    public turnOnPersonalization() {
        this._isRenderPersonalization = true;
    }

    @Throttle(500)
    onScroll() {
        const scrollData = {
            scrollY: window.scrollY,
            scrollX: window.scrollX
        };

        componentEmit(this, "scroll", scrollData);
    }

    @Throttle(500)
    onResize() {
        const resizeData = {
            height: this.offsetHeight,
            width: this.offsetWidth,
            heightChanged: this.previousHeight != this.offsetHeight,
            widthChanged: this.previousWidth != this.offsetWidth
        };
        // emit(this, "resize", {detail: resizeData});

        // todo: make all resize events work with the same emitter like this one
        componentEmit(this, "resize", resizeData);

        this.previousHeight = this.offsetHeight;
        this.previousWidth = this.offsetWidth;

        if (this.idleComplete) {
            return;
        }
        clearInterval(this.idleTimer);
        this.idleTimer = setInterval(() => {
            if (this.isBoardReady()) {
                clearInterval(this.idleTimer);
                console.debug("board is idle - resize");
                this.idleComplete = true;

                // now that the board is loaded and widgets already occupy space in the dom
                // we can navigate to sections
                this.controller.autoScrollAfterLanding();
            }
        }, 1000);
    }

    // checks if we need to update after (requestUpdate) trigger
    protected shouldUpdate(): boolean {
        // return true;
        if (this.generateConfigHash() !== this.configHash) {
            console.debug("should render board");
            return true;
        }

        if (this.forceUpdate) {
            this.forceUpdate = false;
            return true;
        }

        console.debug("skip board render");
        return false;
    }

    // sets the new configHash after update (could be the same - its OK)
    protected updated() {
        this.configHash = this.generateConfigHash();
        this.setMeta({currentPageName: this.currentPage.name});
    }

    protected setMeta(obj: any) {
        if (!this.config.meta) {
            this.config.meta = {};
        }
        this.config.meta = Object.assign(this.config.meta, obj);
    }

    getCurrentPageName(): string {
        return this.currentPage.name;
    }

    setConfig(c: BoardConfig) {
        this._config = c;
        const selectedPage = c.pages[this.selectedPageName];
        if (!selectedPage) {
            console.error(`page ${this.selectedPageName} was not found!`);
            return;
        }
        this.currentPage = selectedPage;
        this.preRender();

        if (!this.isHostedInDesigner) {
            // we need the timeout to wait for the board to start listening
            // - on first setConfig (happens in constructor)
            setTimeout(() => {
                this.registerFloatingWidgetsTriggers();
            });
        }
    }

    set config(c: BoardConfig) {
        if (this.isAutoUpgradeWidgets !== false) {
            c = this.getNewConfigWithUpgradedWidgets(c);
        }
        this.setConfig(c);
    }

    get config(): BoardConfig {
        return this._config;
    }

    set currentPage(pageConfig: PageConfig) {
        console.debug(`liveboard: setting page '${pageConfig.name}'`);
        this.selectedPageName = pageConfig.name;
        this.setForceUpdate();
        this.setMeta({currentPageName: this.selectedPageName});
        this._currentPage = pageConfig;
        this.preRender();
    }

    get currentPage(): PageConfig {
        return this._currentPage;
    }

    get widgets(): WidgetConfig[] {
        return Object.values(this.currentPage.widgets);
    }

    get sortedWidgets(): WidgetConfig[] {
        return sortBy(this.currentPage.widgets, widget => widget.position?.rowStart);
    }

    get floatingWidgets(): FloatingWidgetConfig[] {
        return this._config?.floatingWidgets ? Object.values(this._config.floatingWidgets) : [];
    }

    get widgetElements(): LiveWidget[] {
        return this.getAllWidgetsElements();
    }

    get floatingWidgetElements(): LiveWidget[] {
        // @ts-ignore
        return this.floatingWidgets.map((fc: FloatingWidgetConfig) => this.getFloatEl(fc.id));
    }

    get pages(): PageConfig[] {
        return Object.values(this.config.pages);
    }

    get sections(): SectionConfig[] {
        return Object.values(this.currentPage.sections);
    }

    get ribbons(): RibbonConfig[] {
        return Object.values(this.currentPage.ribbons);
    }

    setPageByName(str: string): void {
        if (this.config.pages.hasOwnProperty(str)) {
            this.currentPage = this.config.pages[str]!;
            console.debug(`change currentPage to: ${str}`);
        }
    }

    generateConfigHash(): string {
        if (this._config) {
            const configClone = stringify(Object.assign({}, this._config, {meta: null}));
            return hashObj(configClone);
        }
        return "";
    }

    // helper function to force board update/refresh. use it before requestUpdate trigger
    setForceUpdate() {
        this.forceUpdate = true;
    }

    getLiveConfigById(id: string): LiveConfig {
        if (this.currentPage.widgets[id]) {
            return this.currentPage.widgets[id]!;
        }
        if (this._config.floatingWidgets && this._config.floatingWidgets[id]) {
            return this._config.floatingWidgets[id]!;
        }
        if (this.currentPage.ribbons[id]) {
            return this.currentPage.ribbons[id]!;
        }

        const config = this.getConfigFromElementById(id);
        if (config) {
            return config;
        }
        throw new Error(`live config "${id}" was not found!`);
    }

    getLoadableConfigById(id: string): LoadableConfig {
        if (this.currentPage.widgets[id]) {
            return this.currentPage.widgets[id]!;
        }
        if (this.currentPage.ribbons[id]) {
            return this.currentPage.ribbons[id]!;
        }
        if (this._config.floatingWidgets && this._config.floatingWidgets[id]) {
            return this._config.floatingWidgets[id]!;
        }

        // @ts-ignore
        const config = this.getConfigFromElementById(id) as LoadableConfig;
        if (config && config.widgetTag && config.widgetScripts) {
            return config;
        }
        throw new Error(`loadable config "${id}" was not found!`);
    }

    getGridConfigById(id: string): GridConfig {
        if (this.currentPage.widgets[id]) {
            return this.currentPage.widgets[id]!;
        }
        if (this.currentPage.ribbons[id]) {
            return this.currentPage.ribbons[id]!;
        }
        throw new Error(`grid config "${id}" was not found!`);
    }

    getWidgetConfig(id: string): WidgetConfig {
        if (this.currentPage.widgets[id]) {
            return this.currentPage.widgets[id]!;
        }
        throw new Error(`widget "${id}" was not found!`);
    }

    getFloatingWidgetConfig(id: string): FloatingWidgetConfig {
        if (this._config?.floatingWidgets?.[id]) {
            return this._config.floatingWidgets[id]!;
        }
        throw new Error(`floating widget "${id}" was not found!`);
    }

    getWidgetEl(id: string): LiveWidget {
        let item = this.shadowRoot!.getElementById(id);
        if (!item && this.isDisjointedEmbeddedBoard) {
            item = document.getElementById(id);
        }
        if (item && item.classList.contains("widget")) {
            return item as LiveWidget;
        }
        throw new Error(`widget element "${id}" was not found!`);
    }

    getRibbonEl(id: string): RibbonElement {
        let item = this.shadowRoot!.getElementById(id);
        if (!item && this.isDisjointedEmbeddedBoard) {
            item = document.getElementById(id);
        }
        if (item && item.classList.contains("ribbon")) {
            return item as RibbonElement;
        }
        throw new Error(`ribbon element "${id}" was not found!`);
    }

    getFloatEl(id: string): FloatWidgetElement | undefined {
        let item = this.shadowRoot!.getElementById(id);
        if (!item && this.isDisjointedEmbeddedBoard) {
            item = document.getElementById(id);
        }
        if (item && item.classList.contains("floating")) {
            return item as FloatWidgetElement;
        }
    }

    getLiveEl(id: string): LiveElement {
        let item = this.shadowRoot!.getElementById(id);
        if (!item && this.isDisjointedEmbeddedBoard) {
            item = document.getElementById(id);
        }
        if (item && (item.classList.contains("widget") || item.classList.contains("ribbon"))) {
            return item as LiveElement;
        }
        throw new Error(`live element "${id}" was not found!`);
    }

    getAllLiveElements(): LiveElement[] {
        const result = [];
        for (const el of this.shadowRoot!.querySelectorAll(".widget, .ribbon")) {
            result.push(el as LiveElement);
        }
        if (this.isDisjointedEmbeddedBoard) {
            for (const el of document.querySelectorAll(".widget, .ribbon")) {
                result.push(el as LiveElement);
            }
        }
        return result;
    }

    getAllGridElements(): GridElement[] {
        const result = [];
        for (const el of this.shadowRoot!.querySelectorAll(".widget:not(.floating), .ribbon")) {
            result.push(el as GridElement);
        }
        return result;
    }

    getAllLoadableElements(): LoadableElement[] {
        const result = [];
        for (const el of this.shadowRoot!.querySelectorAll(".widget, .ribbon")) {
            result.push(el as LiveElement);
        }
        if (this.isDisjointedEmbeddedBoard) {
            for (const el of document.querySelectorAll(".widget, .ribbon")) {
                result.push(el as LiveElement);
            }
        }
        return result as LoadableElement[];
    }

    getAllRibbonElements(): RibbonElement[] {
        const result = [];
        for (const el of this.shadowRoot!.querySelectorAll(".ribbon")) {
            result.push(el as RibbonElement);
        }
        if (this.isDisjointedEmbeddedBoard) {
            for (const el of document.querySelectorAll(".ribbon")) {
                result.push(el as RibbonElement);
            }
        }
        return result;
    }

    getAllWidgetsElements(): LiveWidget[] {
        const result = [];
        for (const el of this.shadowRoot!.querySelectorAll(".widget:not(.floating)")) {
            result.push(el as LiveWidget);
        }
        if (this.isDisjointedEmbeddedBoard) {
            for (const el of document.querySelectorAll(".widget:not(.floating)")) {
                result.push(el as LiveElement);
            }
        }
        return result as LiveWidget[];
    }

    getConfigFromElementById(id: string): LiveConfig | undefined {
        const el = this.getLiveEl(id);
        // @ts-ignore
        if (el && (el.config || el._config)) {
            // @ts-ignore
            return el.config || el._config;
        }
        return undefined;
    }

    getSection(id: string): SectionConfig {
        if (this.currentPage.sections[id]) {
            return this.currentPage.sections[id]!;
        }
        throw new Error(`this section "${id}" was not found!`);
    }

    getRibbonConfig(id: string): RibbonConfig {
        if (this.currentPage.ribbons[id]) {
            return this.currentPage.ribbons[id]!;
        }
        throw new Error(`this ribbon "${id}" was not found!`);
    }

    // todo - refactor - section can have multiple ribbons
    getRibbonBySection(sectionId: string): RibbonConfig {
        // @ts-ignore - we should always have a ribbon for a section (convention)
        return this.ribbons.find(x => x.sectionId === sectionId);
    }

    getRibbonsBySection(sectionId: string) {
        return this.ribbons.filter(x => x.sectionId === sectionId);
    }

    getGridStyling() {
        const grid = this.currentPage.grid;
        return `width: ${grid.maxWidth};
        grid-column-gap: ${grid.gap.x};
        grid-row-gap: ${grid.gap.y};
        grid-template-columns: repeat(${grid.columns.colNum}, ${grid.columns.colWidth});
        grid-template-rows: repeat(${grid.rows.rowNum - 1}, minmax(${grid.rows.rowHeight}, auto));`;
    }

    setRows(n: number) {
        this.currentPage.grid.rows.rowNum = n;
        this.requestUpdate();
    }

    refreshIntersectionObserverList() {
        for (const element of this.intersectionObserverElementsList) {
            this.intersectionObserver.unobserve(element);
        }

        const widgetsEl = this.getAllLiveElements();
        for (const widgetEl of widgetsEl) {
            this.intersectionObserverElementsList.push(widgetEl);
            this.intersectionObserver.observe(widgetEl);
        }
    }

    preRender() {
        if (this.isPreRenderingFlag) {
            console.debug("skip liveboard preRender - already pre rendering");
            return;
        }
        this.isPreRenderingFlag = true;
        this.isWidgetsLoaded = false;
        this.idleComplete = false;

        // override styles
        this.themeOverrideReload();

        // validate global widget loader is in widgets
        if (!this.currentPage.widgets["global-widget-loader"]) {
            this.currentPage.widgets["global-widget-loader"] = {
                "id": "global-widget-loader",
                // @ts-ignore - global-widget-loader is a special widget without section
                "sectionId": null,
                // @ts-ignore - global-widget-loader is a special widget without position
                "position": null,
                "widgetTag": "flz-v-global-widget-loader",
                "widgetScripts": "/widgets/global-widget-loader/global-widget-loader.js",
                data: null,
            };
        }

        let widgetsLoaded = 0;
        let rows = 0;
        waitForFollozeScriptsToLoad().then(() => this.updateComplete).then(async () => {
            const promiseArr: Promise<void>[] = [];
            // todo: when return to personalization development - rethink this
            // promiseArr.push(this.addScriptForWidget(this.sectionStyleWidgetConfig).then());
            const widgetsEl = this.getAllLoadableElements();
            for (const widgetEl of widgetsEl) {
                if (this.widgetsToLoadOnce.includes(widgetEl.id)) {
                    console.debug(`skipped only once load widget | ${widgetEl.id}`);
                    continue;
                }
                const widgetConfig = this.getLoadableConfigById(widgetEl.id) as WidgetConfig;
                const isDifferentWidgetConfig = widgetConfig !== widgetEl.config;
                const isRerenderForPerso = this._isRenderPersonalization && this.isHostedInDesigner;
                promiseArr.push(this.addScriptForWidget(widgetConfig)
                    .then((widgetLoaded: WidgetConfig | void) => {
                        rows = widgetConfig.position?.rowEnd > rows ? widgetConfig.position.rowEnd : rows;
                        if (widgetLoaded || isDifferentWidgetConfig || isRerenderForPerso || !isObjsEqual(widgetConfig, widgetEl.config)) {
                            // remount data to widget element !
                            if (!widgetConfig.data) {
                                widgetConfig.data = widgetEl.data;
                            }
                            if (!this._isRenderPersonalization || !widgetConfig.personalization) {
                                // render widget without personalization
                                widgetEl.config = widgetConfig;
                                console.debug(`data set for ${widgetEl.id}|${widgetConfig.widgetTag}`);
                            }

                            // check if widget has personalization data
                            else if (this.personalizationResolved) {
                                // render widget with personalization
                                const rulesBatchId = widgetConfig.personalization.rulesBatchId;
                                const rulesBatch = this.config.personalization?.rulesBatches[rulesBatchId];
                                if (rulesBatch) {
                                    for (const ruleId of rulesBatch) {
                                        if (this.personalizationResolved[ruleId]) {
                                            widgetConfig.data = widgetConfig.personalization.rulesData[ruleId]?.data;
                                            widgetEl.config = widgetConfig;
                                            console.debug(`personalization data set for ${widgetEl.id}|${widgetConfig.widgetTag}`);
                                            break;
                                        }
                                    }
                                }
                                // if widget is not set by personalization - render it without personalization
                                if (!(widgetEl as LiveWidget).isConfigSet()) {
                                    widgetEl.config = widgetConfig;
                                    console.debug(`data set for ${widgetEl.id}|${widgetConfig.widgetTag} (personalization not set)`);
                                }
                            }

                            // @ts-ignore
                            widgetEl.setConfigOnlyOnce && this.widgetsToLoadOnce.push(widgetEl.id);
                            widgetsLoaded++;
                        } else {
                            console.debug(`skip config set for ${widgetEl.id}|${widgetConfig.widgetTag}`);
                        }
                    })
                    .catch(err => console.error(`can't load scripts for widget ${widgetConfig.widgetTag}`, err)));
            }
            Promise.all(promiseArr).then(() => {
                this.currentPage.grid.rows.rowNum = rows;
                console.debug("finished loading board widgets", widgetsLoaded);
                this.configHash = this.generateConfigHash();
                this.refreshIntersectionObserverList();
                componentEmit(this, "widgets-scripts-loaded", {widgetsLoaded: widgetsLoaded});
                this.isWidgetsLoaded = true;
            }).catch(e => {
                console.error("could not load all scripts for widgets.", e);
            }).finally(() => {
                this.isPreRenderingFlag = false;
            });
        });
    }

    isPersonalized(): boolean {
        const widgetsEl = this.getAllLoadableElements();
        for (const widgetEl of widgetsEl) {
            const widgetConfig = this.getLoadableConfigById(widgetEl.id) as WidgetConfig;
            if(widgetConfig.personalization)
                return true;
        }
        return false;
    }

    getBaseUrlLink(): string {
        // @ts-ignore
        if (typeof RUNTIME_ENV !== 'undefined' && RUNTIME_ENV.ENV_MODE === "development") {
            return "http://localhost:4000/dist";
        }
        const folder = window["WidgetClientBranch"] && window["WidgetClientBranch"] !== "__branch__"
            ? window["WidgetClientBranch"]
            : window["WidgetClientVersion"];
        return `https://cdn.folloze.com/flz/widgets/${folder}`;
    }

    addScriptForWidget(w: LoadableConfig): Promise<LoadableConfig | void> {
        return new Promise((resolve) => {
            resolve(w);
            return;
        });
        // return new Promise((resolve, reject) => {
        //     if (w.widgetScripts.length == 0 || !w.widgetTag) {
        //         // no widget scripts to load
        //         reject();
        //     } else if (customElements.get(w.widgetTag) !== undefined) {
        //         // the widget is already loaded then resolve
        //         resolve();
        //     } else if (this.loadedScripts.has(w.widgetTag)) {
        //         // the widget share script with other widget
        //         this.loadedScripts.get(w.widgetTag).then(() => resolve(w));
        //
        //         // delete if accidentally saved so the designer won't load same edit script twice
        //         delete w._widgetScripts;
        //
        //     } else {
        //         const promiseArray = [];
        //         let scriptToLoad = w.widgetScripts;
        //         if (!w.widgetScripts.startsWith("http")) {
        //             scriptToLoad = this.getBaseUrlLink() + w.widgetScripts;
        //         }
        //
        //         // this is used only in the designer so it will load the edit scripts without doing the same logic above
        //         // w._widgetScripts = scriptToLoad;
        //         this.widgetScriptsLoadMap.set(w.widgetScripts, scriptToLoad);
        //
        //         // we are pushing only one promise but this is ready for multiple
        //         promiseArray.push(new Promise((res, rej) => {
        //             const s = document.createElement("script") as HTMLScriptElement;
        //             s.setAttribute("src", scriptToLoad);
        //             s.setAttribute("type", "module");
        //             s.onerror = () => rej();
        //             s.onload = () => {
        //                 console.debug(`scripts loaded for widget ${scriptToLoad}`);
        //                 res(w);
        //             };
        //             this.appendChild(s);
        //         }));
        //         const widgetLoadingPromise = Promise.all(promiseArray)
        //             .then(() => resolve())
        //             .catch(() => reject());
        //         this.loadedScripts.set(w.widgetTag, widgetLoadingPromise);
        //     }
        // });
    }

    refresh() {
        // todo: maybe find another solution for deep copy? - something more performant
        // for now its only shallow copy (the json is slow)
        // this.config = JSON.parse(JSON.stringify(this.config));

        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(() => {
            this.config = Object.assign({}, this.config);
        });
    }

    refreshPerso(callback?: CallableFunction) {
        this.personalizationResolved = undefined;

        const observer = new MutationObserver((mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === "attributes" && mutation.attributeName === "class") {
                    if (this.classList.contains("personalization-resolved")) {
                        observer.disconnect();
                        callback && callback();
                        break;
                    }
                }
            }
        });

        // Start observing the target node for personalization resolved
        observer.observe(this, {attributes: true, childList: false, subtree: false});
    }


    getNewConfigWithUpgradedWidgets(config: BoardConfig, upgradeLocal: boolean = false): BoardConfig {
        const fullVersion = window["WidgetClientVersion"];
        if (fullVersion) {
            let updatedWidgets = 0;
            const version = fullVersion.replace(/\./gm, "");
            const folder = window["WidgetClientBranch"] && window["WidgetClientBranch"] !== "__branch__"
                ? window["WidgetClientBranch"]
                : fullVersion;
            const replaceValue = upgradeLocal ? "localhost:4000/dist/" : `cdn.folloze.com/flz/widgets/${folder}/`;
            console.debug(replaceValue, version);

            // update all widgets & ribbons in all pages
            for (const pageConfig of Object.values(config.pages)) {
                const widgetIds = Object.keys(pageConfig.widgets).concat(Object.keys(pageConfig.ribbons));
                for (const wId of widgetIds) {
                    const widget = pageConfig.widgets[wId] || pageConfig.ribbons[wId];
                    widget && LiveBoard.upgradeOneWidget(widget, version, replaceValue) && updatedWidgets++;
                }
            }

            // update all floating widgets
            const widgetIds = config.floatingWidgets ? Object.keys(config.floatingWidgets) : [];
            for (const wId of widgetIds) {
                const widget = config.floatingWidgets?.[wId];
                widget && LiveBoard.upgradeOneWidget(widget, version, replaceValue) && updatedWidgets++;
            }

            if (updatedWidgets) {
                console.warn(`Upgraded ${updatedWidgets} widgets to latest version - ${fullVersion}`);
            } else {
                console.debug("all widgets versions are up to date");
            }
        } else {
            console.warn("could not upgrade widget version to latest - missing WidgetClientVersion");
        }
        return config;
    }

    autoUpgradeWidgets(upgradeLocal?: boolean): void {
        const config = this.getNewConfigWithUpgradedWidgets(this.config, upgradeLocal);
        this.setConfig(config);
    }

    private static upgradeOneWidget(widget: LoadableConfig, version: string, url: string): boolean {
        const wTag = widget.widgetTag;
        const wScripts = widget.widgetScripts;

        // update by branch name (since we only update version on master there could be same version for different files)
        if (!wScripts.includes(url)) {
            console.debug(`widget ${wTag} updated to v${version} - (${url})`);
            widget.widgetTag = wTag.replace(/flz-\d+-/img, `flz-${version}-`);
            widget.widgetScripts = widget.widgetScripts.replace(/cdn\.folloze\.com\/flz\/widgets\/\d+\.\d+\.\d+\//img, url);
            return true;
        }
        return false;
    }

    registerFloatingWidgetsTriggers(): void {
        widgetEmit(this, "register-floating-widgets-triggers", {register: true});
    }

    unRegisterFloatingWidgetsTriggers(): void {
        widgetEmit(this, "register-floating-widgets-triggers", {register: false});
    }

    notifyWidgets(e: FlzEvent) {
        this.controller.notifyWidgets(e);
    }

    renderSplit() {
        return html`<div class="live-board"></div>
        ${this.renderFloatingWidgets()}
        `;
    }

    render() {
        if (!isInDesigner() && this.isDisjointedEmbeddedBoard) {
            return this.renderSplit();
        }

        if (!this.config) {
            return html`
                <div class="live-board"></div>`;
        }

        return html`
            ${!this._personalizationResolved || this.liveBoardLoadedPercent < 100
                    ? html`<flz-page-loader loaded="${this.liveBoardLoadedPercent}"></flz-page-loader>` : ""}
            <div class="live-board" style="${this.getGridStyling()}">

                ${this.sortedWidgets.map(x => unsafeHTML(`<${x.widgetTag} 
                    id="${x.id}" 
                    class="widget"
                    style="${getWidgetStyleByPosition(x.position)};">
                </${x.widgetTag}>`))}

                ${this.ribbons.map(r => unsafeHTML(`<${r.widgetTag} 
                    id="${r.id}" 
                    class="ribbon"
                    style="${`grid-area: ${positionToGridArea(r.position)};`}">
                </${r.widgetTag}>`))}

            </div>
            ${this.renderFloatingWidgets()}
        `;
    }

    private renderFloatingWidgets() {
        return html`${this.floatingWidgets.map(x => unsafeHTML(`<${x.widgetTag} id="${x.id}"
                style="${getFloatingWidgetPosition(x)}"
                class="widget floating hidden ${x.hasOverlay && x.floatPos?.isFixedToViewPort ? "overlay" : ""}">
            </${x.widgetTag}>`))}`;
    }
}
