import {LiveBoard} from "../components/LiveBoard";
import {getWidgetStyleByPosition} from "client-sdk";

function addDisjointedEmbeddedStyles() {
    const style = document.createElement("style");
    style.innerHTML = `
    body{overflow-x: hidden !important;}
    .flz-embedded-section {display: grid; grid-template-columns: repeat(12, 1fr); grid-template-rows: repeat(4, minmax(25px, auto));}
    .widget {padding-top: calc(var(--fz-tmp-padd-top)* var(--fz-section-padding-step));
    padding-right: calc(var(--fz-tmp-padd-right)* var(--fz-section-padding-step));
    padding-bottom: calc(var(--fz-tmp-padd-bottom)* var(--fz-section-padding-step));
    padding-left: calc(var(--fz-tmp-padd-left)* var(--fz-section-padding-step));
    scroll-margin-top: calc(var(--edit-fz-system-control-bar-height, 0px) + var(--edit-fz-header-default-height, 0px));}`;
    document.head.appendChild(style);
}

function addDisjointedGlobalWidgetLoader() {
    const version = window["WidgetClientVersion"].replace(/\./gm, "");
    const globalWidgetLoader = document.createElement(`flz-${version}-global-widget-loader`);
    globalWidgetLoader.id = "global-widget-loader";
    globalWidgetLoader.classList.add("widget");
    document.querySelector("flz-liveboard-app")!.parentElement!.appendChild(globalWidgetLoader);
    setTimeout(() => {
        // @ts-ignore
        globalWidgetLoader.appendGlobalComponents();
    });
}

export function runDisjointedEmbeddedLogic(liveBoard: LiveBoard) {
    // add specific styles for embedded sections
    addDisjointedEmbeddedStyles();

    // add global-widget-loader to the board
    addDisjointedGlobalWidgetLoader();

    // add the widgets and ribbons to the board
    document.querySelectorAll("flz-section-template").forEach(section => {
        const sectionId = section.getAttribute("id")!;
        // const sectionConfig = liveBoard.getSection(sectionId);
        const sectionEl = document.createElement("div");
        sectionEl.id = sectionId;
        sectionEl.classList.add("flz-embedded-section");
        section.replaceWith(sectionEl);

        // get the widgets and ribbons
        const widgets = Object.values(liveBoard.currentPage.widgets).filter(widget => widget.sectionId === sectionId);
        const ribbons = Object.values(liveBoard.currentPage.ribbons).filter(ribbon => ribbon.sectionId === sectionId);
        for (const widget of widgets) {
            const widgetEl = document.createElement(widget.widgetTag);
            widgetEl.id = widget.id;
            widgetEl.classList.add("widget");
            widgetEl.setAttribute("style", getWidgetStyleByPosition(widget.position, true)!);
            sectionEl.appendChild(widgetEl);
        }
        for (const ribbon of ribbons) {
            const ribbonEl = document.createElement(ribbon.widgetTag);
            ribbonEl.id = ribbon.id;
            ribbonEl.classList.add("ribbon");
            ribbonEl.setAttribute("style", getWidgetStyleByPosition(ribbon.position, true)!);
            sectionEl.appendChild(ribbonEl);
        }
    });

    setTimeout(() => {
        const tmpWidgets = document.querySelectorAll(".widget");
        for (const tmpWidget of tmpWidgets) {
            const widgetId = tmpWidget.getAttribute("id")!;
            const widget = liveBoard.getLoadableConfigById(widgetId);
            if (widget) {
                const widgetEl = document.createElement(widget.widgetTag);
                widgetEl.id = widgetId;
                for (const attr of tmpWidget.attributes) {
                    widgetEl.setAttribute(attr.name, attr.value);
                }
                tmpWidget.replaceWith(widgetEl);
            }
        }
    });
}